.product-card-container {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: auto;
  justify-content: space-between;
  gap: 20px;
  font-size: small;
}

.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ajusta la altura según sea necesario */
}
.delegate-container {
  font-family: 'Graphik Light';
}

.input-search-home {
  width: 400px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  align-items: center;
  font-family: 'Graphik Light';
  font-size: small;
  height: 10px;
  align-items: center;
}

.input-search-home:focus {
  outline: none;
  border-color: #f2f2f2;
  box-shadow: 0 0 0 2px #dddddd33;
}