.input-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 20px;
}

.styled-input {
  flex-grow: 1;
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Graphik Light';
}

.styled-input:focus {
  border-color: #f2f2f2;
  box-shadow: 0 4px 6px rgba(228, 228, 228, 0.3);
}

.styled-input::placeholder {
  color: #999;
}

.icon {
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-list {
  position: absolute;
  top: calc(100% + 5px); 
  right: 0;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.client-item {
  padding: 10px;
  cursor: pointer;
}

.client-item:hover {
  background-color: #f0f0f0;
}

.styled-hr {
  margin: 0;
}
