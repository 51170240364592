.navbar {
  background: linear-gradient(180deg, #323e48 0%, #333 100%);
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-size: 19px;
  font-family: 'Graphik Light';
  position: sticky;
  top: 0;
  z-index: 999;
  margin-bottom: 10px;
}

.nav-btn-last {
  padding-right: 3px;
}

.nav-links {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  cursor: pointer;
  font-size: 15px;
  display: inline-block;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  transition: border-color 0.3s ease-in-out;
}

.nav-links:hover {
  border-color: #fff;
}

.nav-links-active {
  font-weight: bold;
  font-size: 0.9ºem;
}

.navbar-container {
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  padding-right: 5px;
}

.navbar-logo {
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  font-size: 2rem;
}

.logo {
  margin-top: 10px;
  height: 60px;
}

ul {
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  display: inline-block;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: block;
    justify-content: center;
  }

  .nav-links {
    font-size: 2.3vw;
  }

  li a {
    height: 5vw;
  }

  .navbar-logo,
  .navbar-container {
    padding: 0;
    margin: 0;
  }
  .shop-circle {
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Graphik Light';
  }
}
