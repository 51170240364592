.table-products {
  width: 100%;
  max-width: 1010px;
  border-collapse: collapse;
  font-family: 'Graphik Light';
  font-size: small;
  table-layout: fixed;
}

.table-products th,
.table-products td {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  padding: 8px;
  text-align: center;
  word-wrap: break-word;
}

.table-products th:nth-child(2),
.table-products td:nth-child(2) {
  width: 110px;
}

.table-products th:nth-child(3),
.table-products td:nth-child(3) {
  width: 350px;
  text-align: left;
}

.table-products th:nth-child(4),
.table-products td:nth-child(4) {
  width: 150px;
}
.table-products th:nth-child(5),
.table-products td:nth-child(5),
.table-products th:nth-child(6),
.table-products td:nth-child(6),
.table-products th:nth-child(7),
.table-products td:nth-child(7) {
  width: auto;
}

.meso-button {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: black;
  color: white;
  font-size: x-small;
  height: 30px;
  width: 9rem;
  transition: background-color 0.3s;
}

.meso-button:hover {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: small;
  height: 30px;
  width: 9rem;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #00000000;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 110%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
