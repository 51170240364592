.hole-filter-bar {
  padding-right: 5px;
  width: 250px;
}
.product-type-container {
  margin-top: 20px;
  margin-left: 20px;
  font-family: 'Graphik Light';
  font-size: '9px';
  width: 205px;
}
.filter-tags {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  font-family: 'Graphik Light';
  width: 200px;
}
.filter-tags input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  padding-right: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 100%;
}
.filter-tags:hover input ~ .checkmark {
  background-color: #ccc;
}
.filter-tags:hover input:checked ~ .checkmark {
  background-color: #ccc;
}
.filter-tags input:checked ~ .checkmark {
  background-color: #2196f3;
}
.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}
.filter-tags input:checked ~ .checkmark::after {
  display: block;
}
.filter-tags .checkmark::after {
  top: 6.4px;
  left: 6.4px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}

/* Estilos para el cuadro de selección */
.square-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  margin-right: 8px; /* Ajusta el espaciado entre el cuadro y el texto según sea necesario */
  position: relative;
}

/* Estilos para el tick cuando el checkbox está seleccionado */
.square-checkbox::after {
  content: '\2713'; /* Código unicode para la marca de verificación */
  font-size: 12px; /* Tamaño del tick */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Por defecto el tick no se muestra */
}

/* Mostrar el tick cuando el checkbox está seleccionado */
input[type='checkbox']:checked + .square-checkbox::after {
  display: block;
}

.meso-button-filter {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: 100%;
  height: 30px;
  width: 16rem;
  font-size: small;
  transition: background-color 0.3s;
}
.meso-button-filter:hover {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: rgb(0, 0, 0);
  color: white;
  font-size: 100%;
  height: 30px;
  font-size: small;
  width: 16rem;
}
