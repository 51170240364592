.card-details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.name-product {
  font-size: medium;
  font-family: 'Graphik Light';
}

.code-product {
  font-size: small;
  font-family: 'Graphik Light';
}

.meso-button-card {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: black;
  color: white;
  font-size: small;
  height: 30px;
  width: 14rem;
  transition: background-color 0.3s;
}

.meso-button-card:hover {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: small;
  height: 30px;
  width: 14rem;
}

.meso-button-card-unavailable {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: gray;
  font-size: small;
  height: 30px;
  width: 14rem;
  transition: background-color 0.3s;
}

.card-holder {
  width: 13rem;
  height: 380px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  display: inline-block;
  text-align: center;
}
