.dropdown-list {
  width: 420px;
  max-height: 600px;
  border-top: none;
}

.client-item {
  padding: 2px;
  border-bottom: 0px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-left: 10px;
  font-family: 'Graphik Light';
  font-size: small;
}

.client-item:hover {
  background-color: #f9f9f9;
}
.styled-hr {
  border: none;
  height: 1px;
  background: #f2f2f2;
}
