.main-container-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background {
  background-image: url('../../../public/images/foto_texturas.jpg'); 
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.long-logo {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  width: 300px;
  height: auto;
}
.name-logo {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999;
  width: 300px;
  height: auto;
}
.charge-gif {
  width: 30%;
}
.logo {
  margin-top: 4%;
  width: 5%;
}
.line-splash {
  margin-top: 5%;
}
.line-splash-bottom {
  margin-top: 20%;
}
.microsoft-button {
  /* margin-top: 1%; */
  font-family: 'Graphik Light', sans-serif;
  background-color: black;
  color: white;
  /* font-size: 100%;
  height: 50px;
  width: 150px; */
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.microsoft-button:hover {
  /* margin-top: 1%; */
  font-family: 'Graphik Light', sans-serif;
  background-color: white;
  color: black;
  /* font-size: 100%;
  height: 50px;
  width: 150px; */
}

.login-button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}
.heading-contact {
  margin-top: 3%;
  font-family: 'Graphik Light', sans-serif;
  font-size: 40px;
}

/* Login Form */
.form-container {
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border-radius: 8px;
}

.input-field {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.error {
  color: red;
  margin-bottom: 15px;
  background: white;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}