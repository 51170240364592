.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Graphik Light';
  background-color: rbga(0, 0, 0, 0.7);
}
.modal {
  background-color: white;
  border-radius: 1px;
  padding: 2rem;
  width: 30em;
}
.meso-button-reject {
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: 100%;
  height: 30px;
  width: 15rem;
  cursor: pointer;
  font-size: small;
  transition: background-color 0.3s;
}
.meso-button-reject:reject {
  font-family: 'Graphik Light';
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
  font-size: 100%;
  height: 30px;
  font-size: small;
  width: 15rem;
}
.meso-button-accept {
  font-family: 'Graphik Light';
  background-color: black;
  color: white;
  font-size: small;
  cursor: pointer;
  height: 30px;
  width: 15rem;
  transition: background-color 0.3s;
}
.meso-button-accept:hover {
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: small;
  cursor: pointer;
  height: 30px;
  width: 15rem;
}
.close {
  cursor: pointer;
}
.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}
.modal-content {
  margin-bottom: 1rem;
}
.modal-footer {
  display: flex;
  justify-content: space-evenly;
}
