.meso-button-sidebar {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: black;
  color: white;
  font-size: small;
  height: 30px;
  width: 14rem;
  transition: background-color 0.3s;
}
.meso-button-sidebar-disabled {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: gray;
  font-size: small;
  height: 30px;
  width: 14rem;
  transition: background-color 0.3s;
}
.meso-button-sidebar:hover {
  margin-top: 1%;
  font-family: 'Graphik Light';
  background-color: white;
  color: black;
  font-size: small;
  height: 30px;
  width: 14rem;
}
.sidebar-title {
  font-family: 'Graphik Light';
  font-size: 19px;
}
.counter-div {
  display: flex;
  width: 67px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
}
.icon-substract-add {
  color: #000;
  text-align: center;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: 'Graphik Light';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.36px;
}
.sidebar-input {
  width: 60px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  align-items: center;
  font-family: 'Graphik Light';
  font-size: small;
  height: 10px;
  align-items: center;
}

.sidebar-input:focus {
  outline: none;
  border-color: #f2f2f2;
  box-shadow: 0 0 0 2px #dddddd33;
}
