.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.493); 
  backdrop-filter: blur(0.2px); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-modal-content {
  background: white;
  padding: 10px;
  border-radius: 1px;
  max-width: 200%;
  max-height: 200%;
  overflow: hidden;
}

.image-modal-img {
  width: 350px;
  height: 350px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.image-modal-img.zoomed {
  transform: scale(2); 
}
